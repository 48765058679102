import { Card, SwanStyleProps, VisuallyHidden } from '@vp/swan'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { cardInfo, cardInfoWarningSkin } from './crucial-info-card.module.scss'

export enum CardSkins {
  DEFAULT = 'default',
  WARNING = 'warning',
}

type CrucialInfoProps = {
  skin?: CardSkins
  children?: ReactNode
} & Partial<SwanStyleProps>

export const CrucialInfoCard = ({
  skin = CardSkins.DEFAULT,
  children,
  ...props
}: CrucialInfoProps) => (
  <Card
    my={'4'}
    px={'6'}
    py={'4'}
    className={classNames(cardInfo, {
      [cardInfoWarningSkin]: skin === CardSkins.WARNING,
    })}
    {...props}
  >
    <VisuallyHidden>
      {skin === CardSkins.WARNING ? 'Warning:' : 'Info:'}
    </VisuallyHidden>
    {children}
  </Card>
)
