import { ErrorBoundary } from '@vp/swan'
import { Maybe, SanityCardCriticalInfo } from '../../../../types/gatsby-graphql'
import {
  CardSkins,
  CrucialInfoCard,
} from '../../crucial-info-card/crucial-info-card.component'
import { SchemaMarkdown } from '../../schema-markdown/schema-markdown'

export const SchemaCardCrucialInfo = ({
  node,
}: {
  node?: Maybe<SanityCardCriticalInfo>
}) => {
  if (!node?.content) return null
  return (
    <ErrorBoundary>
      <CrucialInfoCard skin={node.skin as CardSkins}>
        <SchemaMarkdown node={node.content} />
      </CrucialInfoCard>
    </ErrorBoundary>
  )
}
