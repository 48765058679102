import {
  Box,
  Button,
  Li,
  Span,
  StyleBackgroundColor,
  SwanCoreStyleProps,
  Ul,
} from '@vp/swan'
import { useCallback, useRef, useState } from 'react'
import { FaFillDrip } from 'react-icons/fa6'
import { useOutsideClick } from '../../../../hooks/use-outside-click'
import { useCodeContext } from '../code.context'
import {
  color,
  colorContainer,
  colorSelected,
  swatch,
  wrapper,
} from './btn-bgc-selector.module.scss'

const BACKGROUNDS: StyleBackgroundColor[] = [
  'transparent',
  'standard',
  'strong',
  'accent',
  'success',
  'warning',
  'error',
  'help',
  'promo',
  'info',
  'black',
  'holiday',
  'holiday-beige-1',
  'holiday-green-1',
  'holiday-green-2',
  'holiday-red-1',
  'holiday-red-2',
]

export const BtnBgcSelector = (props: SwanCoreStyleProps) => {
  const { bgc, setBgc, codeKey } = useCodeContext()
  const [showDropdown, setShowDropdown] = useState(false)
  const wrapperRef = useRef<HTMLElement>()

  const closeDropdown = useCallback(() => setShowDropdown(false), [])

  useOutsideClick(wrapperRef, closeDropdown)

  const updateBgc = (k: StyleBackgroundColor) => {
    setBgc(k)
  }

  const handleDropdownUpdate = () => {
    setShowDropdown(u => !u)
    setTimeout(() => {
      // adding a timeout because the popover might not have rendered while running below logic
      if (bgc) {
        const elem = document.getElementById(`bgc-${codeKey}-${bgc}`)
        if (elem) {
          elem.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          })
          elem.focus()
        }
      }
    }, 0)
  }

  return (
    <Box ref={wrapperRef} className={wrapper}>
      <Button
        title="Background color"
        buttonShape="round"
        {...props}
        onClick={handleDropdownUpdate}
      >
        <FaFillDrip size="20" />
      </Button>
      <Ul
        skin="minimal"
        role="menu"
        display={showDropdown ? 'block' : 'none'}
        className={colorContainer}
      >
        {BACKGROUNDS.map(bg => (
          <Li p={0} key={bg}>
            <Button
              id={`bgc-${codeKey}-${bg}`}
              className={color && bgc === bg ? colorSelected : color}
              skin="unstyled"
              onClick={() => updateBgc(bg)}
            >
              <Span className="flex-1">{bg}</Span>
              <Span bgc={bg} className={swatch} />
            </Button>
          </Li>
        ))}
      </Ul>
    </Box>
  )
}
