import {
  BasicListbox,
  FlexBox,
  Hidden,
  ListboxOption,
  Typography,
} from '@vp/swan'
import classNames from 'classnames'
import { MutableRefObject, forwardRef } from 'react'
import { LinkItem, NavLink } from '../../base/nav-link/nav-link.component'
import { topNav } from '../layout.module.scss'
import { BtnMenu } from './btn-menu/btn-menu.component'
import { BtnSearch } from './btn-search/btn-search.component'
import { CodePreferences } from './code-preferences/code-preferences.component'
import { Logo } from './logo/logo.component'
import {
  activePageLink,
  landingPageLink,
  navGroup,
  navLink,
} from './top-nav.module.scss'

const vdsLinks: LinkItem[] = [
  {
    url: 'https://careers.vista.com/',
    isExternal: true,
    target: '_blank',
    title: 'Join our team',
  },
]

const Component = (
  {
    isSwanPage,
    path,
  }: {
    isSwanPage: boolean
    path: string
  },
  ref: MutableRefObject<HTMLDivElement>,
) => {
  const links = isSwanPage ? [] : vdsLinks

  const onVersionChange = (value: string) => {
    if (value === 'v2')
      window.location.href = 'https://v2-vds-site.prod.merch.vpsvc.com/swan'
  }

  return (
    <header className={topNav} ref={ref}>
      <nav className={navGroup}>
        <FlexBox alignItems="center">
          {isSwanPage ? <BtnMenu path={path} /> : null}
          <Logo isSwanPage={isSwanPage} />
        </FlexBox>
        <Hidden xs sm>
          {links.map(u => (
            <NavLink
              key={u.title}
              link={u}
              className={classNames(navLink, landingPageLink)}
              partiallyActive
              activeClassName={activePageLink}
            >
              <Typography fontSize="small" fontWeight="bold">
                {u.title}
              </Typography>
            </NavLink>
          ))}
        </Hidden>
        {isSwanPage ? (
          <FlexBox gap={'6'} alignItems="center">
            <BtnSearch />
            <CodePreferences />
            <Hidden xs>
              <BasicListbox
                ml={'6'}
                size="mini"
                aria-label="Select Swan Version"
                value="v3"
                onChange={onVersionChange}
              >
                <ListboxOption key="v3">SWAN v3</ListboxOption>
                <ListboxOption key="v2">SWAN v2</ListboxOption>
              </BasicListbox>
            </Hidden>
          </FlexBox>
        ) : null}
      </nav>
    </header>
  )
}

export const TopNav = forwardRef(Component)
